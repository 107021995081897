import React from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';
import { BiSolidParty } from 'react-icons/bi';
import useActiveNav from './useActiveNav';

const Nav = () => {
  const ids = ['#home', '#about', '#bday'];
  const activeNav = useActiveNav(ids);

  return (
    <nav>
      <a href="#home" className={activeNav === '#home' ? 'active' : ''}>
        <AiOutlineHome />
      </a>
      <a href="#about" className={activeNav === '#about' ? 'active' : ''}>
        <BiUser />
      </a>
      <a href="#bday" className={activeNav === '#bday' ? 'active' : ''}>
        <BiSolidParty />
      </a>
    </nav>
  );
};

export default Nav;
