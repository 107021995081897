import React from 'react';
import loadingImg from '../../assets/loading.gif';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <img src={loadingImg} alt="Loading..." style={{ position: 'fixed', width:'300px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
    </div>
  );
}

export default LoadingScreen;
