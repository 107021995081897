import React from 'react'
import {BsInstagram} from 'react-icons/bs'
import {BsShop} from 'react-icons/bs'
import {BsGlobe} from 'react-icons/bs'


const HeaderSocials = () => {
  return (
    
    <div className="header__socials">

          <a href="https://status.athenarebel.dev/" target="_blank" rel="noreferrer"><BsGlobe/></a>
          <a href="https://socials.athenarebel.dev/snoopy-instagram" target="_blank" rel="noreferrer"><BsInstagram/></a>
          <a href="https://socials.athenarebel.dev/snoopy-merch" target="_blank" rel="noreferrer"><BsShop/></a>
    </div>
  )
}

export default HeaderSocials