import React from 'react'
import './header.css'
import me from '../../assets/me-new.webp'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    
    
      <header id='home'>        
        <div className="container header__container">

          <h5>Howdy! Welcome to my site 💙</h5>
          <h1>C:\Users\AthenaRebel\Snoopy &gt; <span className="cursor-flash">|</span></h1>
          <h1 className='mobile__header'>Snoopy le Doggo</h1>
          <h5 className="text-light">Super Cute dog and Certified Good Boy</h5>
          <HeaderSocials/>

          <div className="me">

          <img src={me} alt='main icon' style={{ width: '400px', height: '390px' }} />


          </div>

          <a href="#about" className='scroll__down'>Scroll Down &#8594;</a>
        </div>
      </header>

    
    
  )
}

export default Header