import React, { useState } from 'react';
import './about.css';
import myImage1 from '../../assets/snoopy_1.webp';
import myImage2 from '../../assets/snoopy_2.webp';
import myImage3 from '../../assets/snoopy_3.webp';
import { GiFishbone } from 'react-icons/gi';
import { BsFillClockFill } from 'react-icons/bs';
import { TfiHeart } from 'react-icons/tfi';

const About = () => {
  const images = [myImage1, myImage2, myImage3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClick = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  return (
    <section id='about'>
      
      <h5>Who's Snoopy?</h5>
      <h2>About Me</h2>

      <div className="container about__container">

          <div className="about__me">
            <div className="about__me-image">
                <img
                  src={images[currentImageIndex]}
                  alt="Me"
                  onClick={handleClick}
                  className="image-transition"
                />
            </div>
          </div>


          <div className="about__content">
            <div className="about__cards">

              <article className='about__card'>
                  <GiFishbone className='about__icon'/>
                  <h5>Favorite Foods</h5>
                  <small>Steak <br /></small>
                  <small>Fish <br /></small>
              </article>

              <article className='about__card'>
                  <TfiHeart className='about__icon'/>
                  <h5>Favorite Toys</h5>
                  <small>
                    <ul>
                      <li>Lamby</li>
                      <li>Baby Yoda Bone</li>
                      <li>Kong Teddy Bear</li>
                      <li>Squeaky Panda Toy</li>
                    </ul>
                  </small>
              </article>

              <article className='about__card'>
                  <BsFillClockFill className='about__icon'/>
                  <h5>Favorite Hobbies</h5>
                  <small>
                    <ul>
                      <li>Sleeping</li>
                      <li>Barking</li>
                    </ul>
                  </small>
              </article>

            </div>
            <ul>
              <li>⠀</li>
              <li> I'm a <b>Dog</b>. I am a <b>Jack Russel</b> mutt, mixed with <b>Corgi</b> and <b>Shizu</b>. </li>
              <li>⠀</li>
              <li> Aside from Dog things, I have a pretty cool parent named <a href="https://athenarebel.dev/" target="_blank" rel="noreferrer">AthenaRebel</a>. <br></br> </li>    
              <li>⠀</li>
              <li> I am 6 years old and my Birthday is March 18th!<br></br> </li>    
            </ul>

          </div>
      </div>
    </section>
  )
}

export default About;
