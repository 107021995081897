import React, { useEffect, useState } from 'react';
import './bday.css'

const Bday = () => {
  const calculateTimeLeft = () => {
    let today = new Date();
    let year = today.getFullYear();
    let birthday = new Date(`03/28/${year}`);
    
    // Check if today is after Snoopy's birthday in the current year
    if (today > birthday) {
      // Calculate next year's birthday
      year++;
      birthday = new Date(`03/28/${year}`);
    }

    let difference = +birthday - +today;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days:  Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours:  Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes:  Math.floor((difference / 1000 / 60) % 60),
        seconds:  Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return (
    <section id='bday'>

      <h5>Bday Countdown</h5>
      <h2>Countdown to Snoopys Bday!!!</h2>
      <div className="bday__countdown" style={{display: 'flex', justifyContent: 'center', fontSize: '2rem'}}>
        {timerComponents.length ? timerComponents : <span>Timer Error! Please notify Athena</span>}
      </div>
    </section>
  )
}

export default Bday